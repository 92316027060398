/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Value.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Value } from "./Value";

import { Project } from "./Project";

export abstract class ValueModel {
    id: number;
    createdAt: Dayjs;
    projectId: number;
    updatedAt: Dayjs;
    valueText: string | null;
    valueTitle: string | null;
    valueType: string | null;
    project: Project | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        createdAt: Dayjs,
        projectId: number,
        updatedAt: Dayjs,
        valueText: string | null,
        valueTitle: string | null,
        valueType: string | null,
        project: Project | null | undefined
    ) {
        this.id = id;
        this.createdAt = createdAt;
        this.projectId = projectId;
        this.updatedAt = updatedAt;
        this.valueText = valueText;
        this.valueTitle = valueTitle;
        this.valueType = valueType;
        this.project = project;
    }
     

     
    static fromApiObject(json: any, timezone: string): Value {
        const newObject = new Value(
            json["id"],
            dayjs(json["created_at"]).tz(timezone),
            json["project_id"],
            dayjs(json["updated_at"]).tz(timezone),
            json["value_text"],
            json["value_title"],
            json["value_type"],
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            created_at: this.createdAt,
            project_id: this.projectId,
            updated_at: this.updatedAt,
            value_text: this.valueText,
            value_title: this.valueTitle,
            value_type: this.valueType,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Value {
        const clone = Object.create(this) as Value;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
