/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `User.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { User } from "./User";

import { Audit } from "./Audit";
import { Email } from "./Email";
import { FireTv } from "./FireTv";
import { MagicToken } from "./MagicToken";
import { Message } from "./Message";
import { Mobile } from "./Mobile";
import { Notification } from "./Notification";
import { NotificationListsUser } from "./NotificationListsUser";
import { Permission } from "./Permission";
import { UserDevice } from "./UserDevice";
import { UsersMostRecentProject } from "./UsersMostRecentProject";
import { VendorLink } from "./VendorLink";

export abstract class UserModel {
    id: number;
    acceptedEula: Dayjs | null;
    createdAt: Dayjs;
    disabled: boolean;
    fullname: string | null;
    guest: boolean | null;
    nickname: string | null;
    passwordDigest: string | null;
    passwordExpires: Dayjs | null;
    preferredLanguage: string | null;
    promptedForNotificationPreferences: boolean | null;
    receiveAppNotifications: boolean | null;
    receiveWebNotifications: boolean | null;
    updatedAt: Dayjs;
    userPreferences: any | null;
    auditableActions: Audit[] | undefined;
    audits: Audit[] | undefined;
    emails: Email[] | undefined;
    fireTvs: FireTv[] | undefined;
    magicTokens: MagicToken[] | undefined;
    mobiles: Mobile[] | undefined;
    notificationListsUsers: NotificationListsUser[] | undefined;
    notifications: Notification[] | undefined;
    permissions: Permission[] | undefined;
    sentMessages: Message[] | undefined;
    userDevices: UserDevice[] | undefined;
    usersMostRecentProjects: UsersMostRecentProject[] | undefined;
    vendorLinks: VendorLink[] | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        acceptedEula: Dayjs | null,
        createdAt: Dayjs,
        disabled: boolean,
        fullname: string | null,
        guest: boolean | null,
        nickname: string | null,
        passwordDigest: string | null,
        passwordExpires: Dayjs | null,
        preferredLanguage: string | null,
        promptedForNotificationPreferences: boolean | null,
        receiveAppNotifications: boolean | null,
        receiveWebNotifications: boolean | null,
        updatedAt: Dayjs,
        userPreferences: any | null,
        auditableActions: Audit[] | undefined,
        audits: Audit[] | undefined,
        emails: Email[] | undefined,
        fireTvs: FireTv[] | undefined,
        magicTokens: MagicToken[] | undefined,
        mobiles: Mobile[] | undefined,
        notificationListsUsers: NotificationListsUser[] | undefined,
        notifications: Notification[] | undefined,
        permissions: Permission[] | undefined,
        sentMessages: Message[] | undefined,
        userDevices: UserDevice[] | undefined,
        usersMostRecentProjects: UsersMostRecentProject[] | undefined,
        vendorLinks: VendorLink[] | undefined
    ) {
        this.id = id;
        this.acceptedEula = acceptedEula;
        this.createdAt = createdAt;
        this.disabled = disabled;
        this.fullname = fullname;
        this.guest = guest;
        this.nickname = nickname;
        this.passwordDigest = passwordDigest;
        this.passwordExpires = passwordExpires;
        this.preferredLanguage = preferredLanguage;
        this.promptedForNotificationPreferences = promptedForNotificationPreferences;
        this.receiveAppNotifications = receiveAppNotifications;
        this.receiveWebNotifications = receiveWebNotifications;
        this.updatedAt = updatedAt;
        this.userPreferences = userPreferences;
        this.auditableActions = auditableActions;
        this.audits = audits;
        this.emails = emails;
        this.fireTvs = fireTvs;
        this.magicTokens = magicTokens;
        this.mobiles = mobiles;
        this.notificationListsUsers = notificationListsUsers;
        this.notifications = notifications;
        this.permissions = permissions;
        this.sentMessages = sentMessages;
        this.userDevices = userDevices;
        this.usersMostRecentProjects = usersMostRecentProjects;
        this.vendorLinks = vendorLinks;
    }
     

     
    static fromApiObject(json: any, timezone: string): User {
        const newObject = new User(
            json["id"],
            json["accepted_eula"] ? dayjs(json["accepted_eula"]).tz(timezone) : null,
            dayjs(json["created_at"]).tz(timezone),
            json["disabled"],
            json["fullname"],
            json["guest"],
            json["nickname"],
            json["password_digest"],
            json["password_expires"] ? dayjs(json["password_expires"]).tz(timezone) : null,
            json["preferred_language"],
            json["prompted_for_notification_preferences"],
            json["receive_app_notifications"],
            json["receive_web_notifications"],
            dayjs(json["updated_at"]).tz(timezone),
            json["user_preferences"],
            json["auditable_actions"]?.map((item: any) => Audit.fromApiObject(item, timezone)),
            json["audits"]?.map((item: any) => Audit.fromApiObject(item, timezone)),
            json["emails"]?.map((item: any) => Email.fromApiObject(item, timezone)),
            json["fire_tvs"]?.map((item: any) => FireTv.fromApiObject(item, timezone)),
            json["magic_tokens"]?.map((item: any) => MagicToken.fromApiObject(item, timezone)),
            json["mobiles"]?.map((item: any) => Mobile.fromApiObject(item, timezone)),
            json["notification_lists_users"]?.map((item: any) => NotificationListsUser.fromApiObject(item, timezone)),
            json["notifications"]?.map((item: any) => Notification.fromApiObject(item, timezone)),
            json["permissions"]?.map((item: any) => Permission.fromApiObject(item, timezone)),
            json["sent_messages"]?.map((item: any) => Message.fromApiObject(item, timezone)),
            json["user_devices"]?.map((item: any) => UserDevice.fromApiObject(item, timezone)),
            json["users_most_recent_projects"]?.map((item: any) => UsersMostRecentProject.fromApiObject(item, timezone)),
            json["vendor_links"]?.map((item: any) => VendorLink.fromApiObject(item, timezone))
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            accepted_eula: this.acceptedEula,
            created_at: this.createdAt,
            disabled: this.disabled,
            fullname: this.fullname,
            guest: this.guest,
            nickname: this.nickname,
            password_digest: this.passwordDigest,
            password_expires: this.passwordExpires,
            preferred_language: this.preferredLanguage,
            prompted_for_notification_preferences: this.promptedForNotificationPreferences,
            receive_app_notifications: this.receiveAppNotifications,
            receive_web_notifications: this.receiveWebNotifications,
            updated_at: this.updatedAt,
            user_preferences: this.userPreferences,
            auditable_actions: this.auditableActions?.map(item => item.toApiObject()),
            audits: this.audits?.map(item => item.toApiObject()),
            emails_attributes: this.emails?.map(item => item.toApiObject()),
            fire_tvs: this.fireTvs?.map(item => item.toApiObject()),
            magic_tokens: this.magicTokens?.map(item => item.toApiObject()),
            mobiles_attributes: this.mobiles?.map(item => item.toApiObject()),
            notification_lists_users: this.notificationListsUsers?.map(item => item.toApiObject()),
            notifications: this.notifications?.map(item => item.toApiObject()),
            permissions: this.permissions?.map(item => item.toApiObject()),
            sent_messages: this.sentMessages?.map(item => item.toApiObject()),
            user_devices: this.userDevices?.map(item => item.toApiObject()),
            users_most_recent_projects: this.usersMostRecentProjects?.map(item => item.toApiObject()),
            vendor_links: this.vendorLinks?.map(item => item.toApiObject()),
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): User {
        const clone = Object.create(this) as User;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
