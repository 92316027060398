/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `ActivitiesOrder.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { ActivitiesOrder } from "./ActivitiesOrder";

import { Activity } from "./Activity";
import { Order } from "./Order";

export type dependency = "start" | "finish";

export const dependencyValues = {"start":0,"finish":1};

export abstract class ActivitiesOrderModel {
    id: number;
    activityId: number;
    dependency: dependency;
    orderId: number;
    activity: Activity | null | undefined;
    order: Order | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        activityId: number,
        dependency: dependency,
        orderId: number,
        activity: Activity | null | undefined,
        order: Order | null | undefined
    ) {
        this.id = id;
        this.activityId = activityId;
        this.dependency = dependency;
        this.orderId = orderId;
        this.activity = activity;
        this.order = order;
    }
     

     
    static fromApiObject(json: any, timezone: string): ActivitiesOrder {
        const newObject = new ActivitiesOrder(
            json["id"],
            json["activity_id"],
            json["dependency"],
            json["order_id"],
            json["activity"] == null ? json["activity"] : Activity.fromApiObject(json["activity"], timezone),
            json["order"] == null ? json["order"] : Order.fromApiObject(json["order"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            activity_id: this.activityId,
            dependency: this.dependency,
            order_id: this.orderId,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): ActivitiesOrder {
        const clone = Object.create(this) as ActivitiesOrder;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
