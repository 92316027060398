import { VendorCache } from "../VendorCache/vendorCacheTypes";

export class Submittal {
    id: number;
    material_group_id: number;
    project_id: number;

    // Commmon columns for all submittals
    title: string;
    status: string | null;
    submittal_type: string | null;
    label: string | null;
    lead_time: number | null;
    required_on_jobsite: Date | null;
    approval_date: Date | null;
    submit_date: Date | null;
    distribution_date: Date | null;
    submittal_number: string | null;
    spec_number: string | null;
    connected_count: number;

    // Cache object storing the external vendor data
    vendor_cache: VendorCache | null;

    static fromApiObject(obj: any): Submittal {
        const submittal = new Submittal();

        submittal.id = obj.id;
        submittal.material_group_id = obj.material_group_id;
        submittal.project_id = obj.project_id;

        submittal.title = obj.title;
        submittal.status = obj.status;
        submittal.submittal_type = obj.submittal_type;
        submittal.label = obj.label;
        submittal.lead_time = obj.lead_time;
        submittal.required_on_jobsite = obj.required_on_jobsite ? new Date(obj.required_on_jobsite) : null;
        submittal.approval_date = obj.approval_date ? new Date(obj.approval_date) : null;
        submittal.submit_date = obj.submit_date ? new Date(obj.submit_date) : null;
        submittal.distribution_date = obj.distribution_date ? new Date(obj.distribution_date) : null;
        submittal.submittal_number = obj.submittal_number;
        submittal.spec_number = obj.spec_number;
        submittal.connected_count = obj.connected_count;

        submittal.vendor_cache = obj.vendor_cache ? VendorCache.fromApiObject(obj.vendor_cache) : null;

        return submittal;
    }

    toApiObject(): any {
        return {
            id: this.id,
            material_group_id: this.material_group_id,
            project_id: this.project_id,
            vendor_cache_attributes: this.vendor_cache?.toApiObject(),

            title: this.title,
            status: this.status,
            submittal_type: this.submittal_type,
            label: this.label,
            lead_time: this.lead_time,
            required_on_jobsite: this.required_on_jobsite,
            approval_date: this.approval_date,
            distribution_date: this.distribution_date,
        };
    }
}
