/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `ProjectsWeatherAlert.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { ProjectsWeatherAlert } from "./ProjectsWeatherAlert";

import { Project } from "./Project";
import { WeatherAlert } from "./WeatherAlert";

export abstract class ProjectsWeatherAlertModel {
    id: number;
    projectId: number | null;
    weatherAlertId: number | null;
    project: Project | null | undefined;
    weatherAlert: WeatherAlert | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        projectId: number | null,
        weatherAlertId: number | null,
        project: Project | null | undefined,
        weatherAlert: WeatherAlert | null | undefined
    ) {
        this.id = id;
        this.projectId = projectId;
        this.weatherAlertId = weatherAlertId;
        this.project = project;
        this.weatherAlert = weatherAlert;
    }
     

     
    static fromApiObject(json: any, timezone: string): ProjectsWeatherAlert {
        const newObject = new ProjectsWeatherAlert(
            json["id"],
            json["project_id"],
            json["weather_alert_id"],
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone),
            json["weather_alert"] == null ? json["weather_alert"] : WeatherAlert.fromApiObject(json["weather_alert"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            project_id: this.projectId,
            weather_alert_id: this.weatherAlertId,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): ProjectsWeatherAlert {
        const clone = Object.create(this) as ProjectsWeatherAlert;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
