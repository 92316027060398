/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `AreaExceptionDay.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { AreaExceptionDay } from "./AreaExceptionDay";

import { Area } from "./Area";

export abstract class AreaExceptionDayModel {
    id: number;
    areaId: number | null;
    createdAt: Dayjs;
    exceptionEnd: Dayjs;
    exceptionName: string | null;
    exceptionStart: Dayjs;
    updatedAt: Dayjs;
    area: Area | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        areaId: number | null,
        createdAt: Dayjs,
        exceptionEnd: Dayjs,
        exceptionName: string | null,
        exceptionStart: Dayjs,
        updatedAt: Dayjs,
        area: Area | null | undefined
    ) {
        this.id = id;
        this.areaId = areaId;
        this.createdAt = createdAt;
        this.exceptionEnd = exceptionEnd;
        this.exceptionName = exceptionName;
        this.exceptionStart = exceptionStart;
        this.updatedAt = updatedAt;
        this.area = area;
    }
     

     
    static fromApiObject(json: any, timezone: string): AreaExceptionDay {
        const newObject = new AreaExceptionDay(
            json["id"],
            json["area_id"],
            dayjs(json["created_at"]).tz(timezone),
            dayjs(json["exception_end"]).tz(timezone),
            json["exception_name"],
            dayjs(json["exception_start"]).tz(timezone),
            dayjs(json["updated_at"]).tz(timezone),
            json["area"] == null ? json["area"] : Area.fromApiObject(json["area"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            area_id: this.areaId,
            created_at: this.createdAt,
            exception_end: this.exceptionEnd,
            exception_name: this.exceptionName,
            exception_start: this.exceptionStart,
            updated_at: this.updatedAt,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): AreaExceptionDay {
        const clone = Object.create(this) as AreaExceptionDay;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
