/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Submittal.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Submittal } from "./Submittal";

import { MaterialGroupsSubmittal } from "./MaterialGroupsSubmittal";
import { MaterialsSubmittal } from "./MaterialsSubmittal";
import { Project } from "./Project";
import { VendorCache } from "./VendorCache";

export abstract class SubmittalModel {
    id: number;
    approvalDate: Dayjs | null;
    createdAt: Dayjs;
    label: string | null;
    leadTime: number | null;
    projectId: number | null;
    requiredOnJobsite: Dayjs | null;
    specNumber: string | null;
    status: string | null;
    submitDate: Dayjs | null;
    submittalNumber: string | null;
    submittalType: string | null;
    title: string | null;
    updatedAt: Dayjs;
    materialGroupsSubmittals: MaterialGroupsSubmittal[] | undefined;
    materialsSubmittals: MaterialsSubmittal[] | undefined;
    project: Project | null | undefined;
    vendorCache: VendorCache | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        approvalDate: Dayjs | null,
        createdAt: Dayjs,
        label: string | null,
        leadTime: number | null,
        projectId: number | null,
        requiredOnJobsite: Dayjs | null,
        specNumber: string | null,
        status: string | null,
        submitDate: Dayjs | null,
        submittalNumber: string | null,
        submittalType: string | null,
        title: string | null,
        updatedAt: Dayjs,
        materialGroupsSubmittals: MaterialGroupsSubmittal[] | undefined,
        materialsSubmittals: MaterialsSubmittal[] | undefined,
        project: Project | null | undefined,
        vendorCache: VendorCache | null | undefined
    ) {
        this.id = id;
        this.approvalDate = approvalDate;
        this.createdAt = createdAt;
        this.label = label;
        this.leadTime = leadTime;
        this.projectId = projectId;
        this.requiredOnJobsite = requiredOnJobsite;
        this.specNumber = specNumber;
        this.status = status;
        this.submitDate = submitDate;
        this.submittalNumber = submittalNumber;
        this.submittalType = submittalType;
        this.title = title;
        this.updatedAt = updatedAt;
        this.materialGroupsSubmittals = materialGroupsSubmittals;
        this.materialsSubmittals = materialsSubmittals;
        this.project = project;
        this.vendorCache = vendorCache;
    }
     

     
    static fromApiObject(json: any, timezone: string): Submittal {
        const newObject = new Submittal(
            json["id"],
            json["approval_date"] ? dayjs(json["approval_date"]).tz(timezone) : null,
            dayjs(json["created_at"]).tz(timezone),
            json["label"],
            json["lead_time"],
            json["project_id"],
            json["required_on_jobsite"] ? dayjs(json["required_on_jobsite"]).tz(timezone) : null,
            json["spec_number"],
            json["status"],
            json["submit_date"] ? dayjs(json["submit_date"]).tz(timezone) : null,
            json["submittal_number"],
            json["submittal_type"],
            json["title"],
            dayjs(json["updated_at"]).tz(timezone),
            json["material_groups_submittals"]?.map((item: any) => MaterialGroupsSubmittal.fromApiObject(item, timezone)),
            json["materials_submittals"]?.map((item: any) => MaterialsSubmittal.fromApiObject(item, timezone)),
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone),
            json["vendor_cache"] == null ? json["vendor_cache"] : VendorCache.fromApiObject(json["vendor_cache"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            approval_date: this.approvalDate,
            created_at: this.createdAt,
            label: this.label,
            lead_time: this.leadTime,
            project_id: this.projectId,
            required_on_jobsite: this.requiredOnJobsite,
            spec_number: this.specNumber,
            status: this.status,
            submit_date: this.submitDate,
            submittal_number: this.submittalNumber,
            submittal_type: this.submittalType,
            title: this.title,
            updated_at: this.updatedAt,
            material_groups_submittals: this.materialGroupsSubmittals?.map(item => item.toApiObject()),
            materials_submittals: this.materialsSubmittals?.map(item => item.toApiObject()),
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Submittal {
        const clone = Object.create(this) as Submittal;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
