/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `DeliveryHasTeam.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { DeliveryHasTeam } from "./DeliveryHasTeam";

import { Audit } from "./Audit";
import { Delivery } from "./Delivery";
import { Team } from "./Team";

export type deliveryRole = "gc_team" | "receiving_team";

export const deliveryRoleValues = {"gc_team":0,"receiving_team":1};

export abstract class DeliveryHasTeamModel {
    id: number;
    approvedDelivery: boolean | null;
    deliveryId: number;
    deliveryRole: deliveryRole;
    teamId: number;
    audits: Audit[] | undefined;
    delivery: Delivery | null | undefined;
    team: Team | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        approvedDelivery: boolean | null,
        deliveryId: number,
        deliveryRole: deliveryRole,
        teamId: number,
        audits: Audit[] | undefined,
        delivery: Delivery | null | undefined,
        team: Team | null | undefined
    ) {
        this.id = id;
        this.approvedDelivery = approvedDelivery;
        this.deliveryId = deliveryId;
        this.deliveryRole = deliveryRole;
        this.teamId = teamId;
        this.audits = audits;
        this.delivery = delivery;
        this.team = team;
    }
     

     
    static fromApiObject(json: any, timezone: string): DeliveryHasTeam {
        const newObject = new DeliveryHasTeam(
            json["id"],
            json["approved_delivery"],
            json["delivery_id"],
            json["delivery_role"],
            json["team_id"],
            json["audits"]?.map((item: any) => Audit.fromApiObject(item, timezone)),
            json["delivery"] == null ? json["delivery"] : Delivery.fromApiObject(json["delivery"], timezone),
            json["team"] == null ? json["team"] : Team.fromApiObject(json["team"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            approved_delivery: this.approvedDelivery,
            delivery_id: this.deliveryId,
            delivery_role: this.deliveryRole,
            team_id: this.teamId,
            audits: this.audits?.map(item => item.toApiObject()),
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): DeliveryHasTeam {
        const clone = Object.create(this) as DeliveryHasTeam;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
