/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `EquipmentBreakTime.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { EquipmentBreakTime } from "./EquipmentBreakTime";

import { Equipment } from "./Equipment";

export abstract class EquipmentBreakTimeModel {
    id: number;
    breakEnd: Dayjs | null;
    breakName: string | null;
    breakStart: Dayjs | null;
    createdAt: Dayjs;
    equipmentId: number | null;
    updatedAt: Dayjs;
    equipment: Equipment | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        breakEnd: Dayjs | null,
        breakName: string | null,
        breakStart: Dayjs | null,
        createdAt: Dayjs,
        equipmentId: number | null,
        updatedAt: Dayjs,
        equipment: Equipment | null | undefined
    ) {
        this.id = id;
        this.breakEnd = breakEnd;
        this.breakName = breakName;
        this.breakStart = breakStart;
        this.createdAt = createdAt;
        this.equipmentId = equipmentId;
        this.updatedAt = updatedAt;
        this.equipment = equipment;
    }
     

     
    static fromApiObject(json: any, timezone: string): EquipmentBreakTime {
        const newObject = new EquipmentBreakTime(
            json["id"],
            json["break_end"] ? dayjs(json["break_end"]).tz(timezone) : null,
            json["break_name"],
            json["break_start"] ? dayjs(json["break_start"]).tz(timezone) : null,
            dayjs(json["created_at"]).tz(timezone),
            json["equipment_id"],
            dayjs(json["updated_at"]).tz(timezone),
            json["equipment"] == null ? json["equipment"] : Equipment.fromApiObject(json["equipment"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            break_end: this.breakEnd,
            break_name: this.breakName,
            break_start: this.breakStart,
            created_at: this.createdAt,
            equipment_id: this.equipmentId,
            updated_at: this.updatedAt,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): EquipmentBreakTime {
        const clone = Object.create(this) as EquipmentBreakTime;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
