/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Email.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Email } from "./Email";

import { Audit } from "./Audit";
import { User } from "./User";

export abstract class EmailModel {
    id: number;
    createdAt: Dayjs;
    email: string | null;
    emailType: string | null;
    receiveNotifications: boolean | null;
    updatedAt: Dayjs;
    userId: number | null;
    verificationToken: string | null;
    verified: boolean | null;
    audits: Audit[] | undefined;
    user: User | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        createdAt: Dayjs,
        email: string | null,
        emailType: string | null,
        receiveNotifications: boolean | null,
        updatedAt: Dayjs,
        userId: number | null,
        verificationToken: string | null,
        verified: boolean | null,
        audits: Audit[] | undefined,
        user: User | null | undefined
    ) {
        this.id = id;
        this.createdAt = createdAt;
        this.email = email;
        this.emailType = emailType;
        this.receiveNotifications = receiveNotifications;
        this.updatedAt = updatedAt;
        this.userId = userId;
        this.verificationToken = verificationToken;
        this.verified = verified;
        this.audits = audits;
        this.user = user;
    }
     

     
    static fromApiObject(json: any, timezone: string): Email {
        const newObject = new Email(
            json["id"],
            dayjs(json["created_at"]).tz(timezone),
            json["email"],
            json["email_type"],
            json["receive_notifications"],
            dayjs(json["updated_at"]).tz(timezone),
            json["user_id"],
            json["verification_token"],
            json["verified"],
            json["audits"]?.map((item: any) => Audit.fromApiObject(item, timezone)),
            json["user"] == null ? json["user"] : User.fromApiObject(json["user"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            created_at: this.createdAt,
            email: this.email,
            email_type: this.emailType,
            receive_notifications: this.receiveNotifications,
            updated_at: this.updatedAt,
            user_id: this.userId,
            verification_token: this.verificationToken,
            verified: this.verified,
            audits: this.audits?.map(item => item.toApiObject()),
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Email {
        const clone = Object.create(this) as Email;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
