import { isPast, sub } from "date-fns";
import { Delivery } from "../../models/Delivery";
import { ActivitiesOrder, Activity } from "../Activity/activityTypes";
import { Material } from "./material";

export class ChangeHistoryPoint {
    id: number;
    user: null | { id: number; fullname: string; nickname: string };
    change: any;
    created_at: Date;

    order_id: number;
    order_name: string;
    material_name: string;
    material_group_name: string;
    has_material_group: boolean;

    changeValues = (column_name: string): { previous: string; now: string } | null => {
        const found_row = this.change[column_name];

        if (!found_row) {
            return null;
        }

        switch (true) {
            // Catch all of the filtered out cases first
            case /.*_updated/gm.test(column_name): // These are "hidden" values so users do not need to see these
                return null;
            case /.*_locked/gm.test(column_name): // These are "hidden" values so users do not need to see these
                return null;
        }

        return { previous: found_row[0], now: found_row[1] };
    };

    changeValueStrings = (column_name: string): { previous: string; now: string } | null => {
        const changeValues = this.changeValues(column_name);

        if (!changeValues) {
            return null;
        }

        if (column_name == "order_by") {
            changeValues.previous = this.formattedDateFromString(changeValues.previous, true);
            changeValues.now = this.formattedDateFromString(changeValues.now, true);
        }

        if (
            column_name == "ordered" ||
            column_name == "required_on_jobsite" ||
            column_name == "anticipated_on_jobsite" ||
            column_name == "anticipated_order" ||
            column_name == "submit_by" ||
            column_name == "approve_by"
        ) {
            changeValues.previous = this.formattedDateFromString(changeValues.previous);
            changeValues.now = this.formattedDateFromString(changeValues.now);
        }

        return changeValues;
    };

    columnNameToFormatted = (column: string): string => {
        const parts = column.split("_");
        const capitalized = parts.map((item) => item[0].toUpperCase() + item.substring(1));

        return capitalized.join(" ");
    };

    renderableChanges = (): string[] => {
        if (!this.change) {
            return [];
        }

        const changes: string[] = [];
        Object.entries(this.change).forEach(([change_name]) => {
            const change = this.changeValueStrings(change_name);

            switch (true) {
                case change_name.includes("material_id"):
                    changes.push(`Created Order for Material (${this.material_name || "Material"})`);
                    return;
                case change_name.includes("delivery_id"):
                    changes.push(`Created a Delivery for Order`);
                    return;
                case change_name.includes("activity_ids"):
                    changes.push(`Updated Connected Activities`);
                    return;
                case !change:
                    // Skip if no changes to track as we already printed the stuff that does not track before's and after's
                    return;
                case change_name.includes("order_by"):
                    changes.push(`Updated 'Order Deadline' from ${change.previous} to ${change.now}`);
                    return;
                case change_name.includes("ordered"):
                    changes.push(`Updated 'Order Date' from ${change.previous} to ${change.now}`);
                    return;
                case change_name.includes("required_on_jobsite"):
                    changes.push(`Updated 'Required On Site' from ${change.previous} to ${change.now}`);
                    return;
                case change_name.includes("order_name"):
                    changes.push(
                        `Updated 'Order Name' from ${
                            change.previous && change.previous.length > 0 ? change.previous : "empty"
                        } to ${change.now && change.now.length > 0 ? change.now : "empty"}`
                    );
                    return;
                case change_name.includes("status"):
                    changes.push(
                        `Updated 'Status' from '${OrderStatus.fromNumber(
                            parseInt(change.previous)
                        ).pretty()}' to '${OrderStatus.fromNumber(parseInt(change.now)).pretty()}'`
                    );
                    return;
                default:
                    changes.push(
                        `Updated '${this.columnNameToFormatted(change_name)}' from ${
                            change.previous ? change.previous : "empty"
                        } to ${change.now ? change.now : "empty"}`
                    );
                    return;
            }
        });

        return changes;
    };

    static fromApiObject(obj: any): ChangeHistoryPoint {
        const change = new ChangeHistoryPoint();
        change.id = obj.id;
        change.user = obj.user;
        change.change = typeof obj.change == "string" ? JSON.parse(obj.change) : obj.change;
        change.created_at = new Date(obj.created_at);

        change.order_id = obj.order_id;
        change.order_name = obj.order_name;
        change.material_name = obj.material_name;
        change.material_group_name = obj.material_group_name;
        change.has_material_group = obj.has_material_group;

        return change;
    }

    private formattedDateFromString = (date_str: string, default_today: boolean = false): string => {
        if (!default_today && (!date_str || date_str.length == 0)) {
            return "empty";
        }

        const date = new Date(date_str);

        return date.toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric",
            year: "2-digit",
        });
    };
}

export class ChangeHistoryMessage {
    id: number;
    created_at: Date;
    updated_at: Date;
    user: any;
    message: string;
    files: any[];

    static fromApiObject(message: any): ChangeHistoryMessage {
        const new_message = new ChangeHistoryMessage();
        new_message.id = message.id;
        new_message.created_at = new Date(message.created_at);
        new_message.updated_at = new Date(message.updated_at);
        new_message.user = message.user;
        new_message.message = message.body;
        new_message.files = message.files;

        return new_message;
    }
}

export class CheckInResponse {
    response: number = 0;
    responseName: string = "";

    static ON_TRACK: CheckInResponse = new CheckInResponse(0, "ON_TRACK");
    static OFF_TRACK: CheckInResponse = new CheckInResponse(1, "OFF_TRACK");

    constructor(response: number, responseName: string) {
        this.response = response;
        this.responseName = responseName;
    }

    static fromID(id: number): CheckInResponse {
        if (id == this.ON_TRACK.response) {
            return this.ON_TRACK;
        } else if (id == this.OFF_TRACK.response) {
            return this.OFF_TRACK;
        }

        return this.ON_TRACK;
    }

    getName(): string {
        return this.responseName;
    }
}

export class OrderCheckIn {
    id: number;
    created_at: Date;
    updated_at: Date;
    user: any;
    response: CheckInResponse;
    comments: string;
    files: any[];

    static fromApiObject(checkin: any): OrderCheckIn {
        const new_checkin = new OrderCheckIn();
        new_checkin.id = checkin.id;
        new_checkin.created_at = new Date(checkin.created_at);
        new_checkin.updated_at = new Date(checkin.updated_at);
        new_checkin.user = checkin.user;
        new_checkin.response = CheckInResponse.fromID(checkin.response);
        new_checkin.comments = checkin.comments;
        new_checkin.files = checkin.files;

        return new_checkin;
    }

    toApiObject(): any {
        return {
            id: this.id,
            user: this.user,
            response: this.response.response,
            comments: this.comments,
        };
    }
}

export class OrderStatus {
    type: number = 0;
    typeName: string = "";

    static NEEDS_ROJ: OrderStatus = new OrderStatus(5, "needs_roj");
    static NEEDS_LEAD_TIME: OrderStatus = new OrderStatus(10, "needs_lead_time");
    static NEEDS_MATERIAL_SUBMITTED: OrderStatus = new OrderStatus(15, "needs_material_submitted");
    static NEEDS_MATERIAL_APPROVED: OrderStatus = new OrderStatus(20, "needs_material_approved");
    static NEEDS_ORDERED: OrderStatus = new OrderStatus(25, "needs_ordered");
    static NEEDS_SCHEDULED: OrderStatus = new OrderStatus(30, "needs_scheduled");
    static NEEDS_RECEIVED: OrderStatus = new OrderStatus(35, "needs_received");
    static COMPLETE: OrderStatus = new OrderStatus(40, "complete");

    constructor(type: number, typeName: string) {
        this.type = type;
        this.typeName = typeName;
    }

    static fromNumber(status: number): OrderStatus {
        if (status == this.NEEDS_ROJ.type) {
            return this.NEEDS_ROJ;
        } else if (status == this.NEEDS_LEAD_TIME.type) {
            return this.NEEDS_LEAD_TIME;
        } else if (status == this.NEEDS_MATERIAL_SUBMITTED.type) {
            return this.NEEDS_MATERIAL_SUBMITTED;
        } else if (status == this.NEEDS_MATERIAL_APPROVED.type) {
            return this.NEEDS_MATERIAL_APPROVED;
        } else if (status == this.NEEDS_ORDERED.type) {
            return this.NEEDS_ORDERED;
        } else if (status == this.NEEDS_SCHEDULED.type) {
            return this.NEEDS_SCHEDULED;
        } else if (status == this.NEEDS_RECEIVED.type) {
            return this.NEEDS_RECEIVED;
        } else if (status == this.COMPLETE.type) {
            return this.COMPLETE;
        }

        return this.NEEDS_ROJ;
    }

    static fromString(status: string | undefined): OrderStatus {
        if (!status) {
            return this.NEEDS_ROJ;
        }

        if (status == this.NEEDS_ROJ.typeName) {
            return this.NEEDS_ROJ;
        } else if (status == this.NEEDS_LEAD_TIME.typeName) {
            return this.NEEDS_LEAD_TIME;
        } else if (status == this.NEEDS_MATERIAL_SUBMITTED.typeName) {
            return this.NEEDS_MATERIAL_SUBMITTED;
        } else if (status == this.NEEDS_MATERIAL_APPROVED.typeName) {
            return this.NEEDS_MATERIAL_APPROVED;
        } else if (status == this.NEEDS_ORDERED.typeName) {
            return this.NEEDS_ORDERED;
        } else if (status == this.NEEDS_SCHEDULED.typeName) {
            return this.NEEDS_SCHEDULED;
        } else if (status == this.NEEDS_RECEIVED.typeName) {
            return this.NEEDS_RECEIVED;
        } else if (status == this.COMPLETE.typeName) {
            return this.COMPLETE;
        }

        return this.NEEDS_ROJ;
    }

    isAtLeast(status: OrderStatus): boolean {
        return this.type >= status.type;
    }

    isAtMost(status: OrderStatus): boolean {
        return this.type <= status.type;
    }

    pretty(): string {
        return this.typeName
            .split("_")
            .map((str) => (str.length > 0 ? str[0] + str.slice(1).toLowerCase() : null))
            .filter((str) => str != null)
            .join(" ");
    }
}

export class Order {
    id: number;
    project: any;
    project_unique_token: string;
    material_id: number | null;

    serial_number: number;

    activity_ids: number[];
    activities: Activity[];
    activities_orders: ActivitiesOrder[];

    status: OrderStatus;

    anticipated_on_jobsite: Date | null;

    archived: boolean;
    check_in_frequency: number | null;

    cushion: number | null;

    order_name: string | null;
    lead_time: number | null;
    lead_time_updated: Date | null;

    order_by: Date | null;
    order_by_adjusted: Date | null;

    ordered: Date | null;
    ordered_updated: Date | null;
    quantity: number | null;
    required_on_jobsite: Date | null;
    required_on_jobsite_updated: Date | null;
    shakeout_days: number;
    needs_check_in: boolean;

    delivery: Delivery | null;
    material: Material;
    history: ChangeHistoryPoint[];
    check_ins: OrderCheckIn[];

    static fromApiObject(order: any): Order {
        const new_order = new Order();

        new_order.id = order.id;
        new_order.project = order.project;
        new_order.project_unique_token = order.project_unique_token;
        new_order.material_id = order.material_id;

        // Attributes
        new_order.serial_number = order.serial_number;
        new_order.activities = order.activities?.map((activity) => Activity.fromApiObject(activity)) || [];
        new_order.activities_orders =
            order.activities_orders?.map((activity_order) => ActivitiesOrder.fromApiObject(activity_order)) || [];
        new_order.status = OrderStatus.fromString(order.status);
        new_order.anticipated_on_jobsite = order.anticipated_on_jobsite ? new Date(order.anticipated_on_jobsite) : null;
        new_order.archived = order.archived;
        new_order.check_in_frequency = order.check_in_frequency;
        new_order.cushion = order.cushion;
        new_order.order_name = order.order_name;
        new_order.lead_time = order.lead_time;
        new_order.lead_time_updated = order.lead_time_updated ? new Date(order.lead_time_updated) : null;
        new_order.order_by = order.order_by ? new Date(order.order_by) : null;
        new_order.required_on_jobsite = order.required_on_jobsite ? new Date(order.required_on_jobsite) : null;
        new_order.required_on_jobsite_updated = order.required_on_jobsite_updated
            ? new Date(order.required_on_jobsite_updated)
            : null;
        new_order.ordered = order.ordered ? new Date(order.ordered) : null;
        new_order.ordered_updated = order.ordered_updated ? new Date(order.ordered_updated) : null;
        new_order.quantity = order.quantity;
        new_order.shakeout_days = order.shakeout_days;
        new_order.needs_check_in = order.needs_check_in;

        // Calculate the adjusted values (if in the past move it up to today)
        // Order By
        if (new_order.order_by) {
            if (isPast(new_order.order_by)) {
                new_order.order_by_adjusted = new Date();
            } else {
                new_order.order_by_adjusted = new_order.order_by;
            }
        }

        // Relationships
        if (order.delivery) {
            new_order.delivery = Delivery.fromApiObject(order.delivery, "ETC/utc");
        }

        if (order.material) {
            order.material.orders = [];
            new_order.material = Material.fromApiObject(order.material);
        }

        new_order.history = order.history ? order.history.map((point) => ChangeHistoryPoint.fromApiObject(point)) : [];

        new_order.check_ins = order.check_ins
            ? order.check_ins.map((check_in) => OrderCheckIn.fromApiObject(check_in))
            : [];

        return new_order;
    }

    toApiObject(): any {
        return {
            id: this.id,
            material_id: this.material_id,
            activity_ids: this.activities.map((activity) => activity.id),
            activities_orders_attributes: this.activities_orders.map((activity_order) => activity_order.toApiObject()),
            anticipated_on_jobsite: this.anticipated_on_jobsite,
            archived: this.archived,
            check_in_frequency: this.check_in_frequency,
            cushion: this.cushion,
            order_name: this.order_name,
            lead_time: this.lead_time,
            lead_time_updated: this.lead_time_updated,
            order_by: this.order_by,
            ordered: this.ordered,
            ordered_updated: this.ordered_updated,
            quantity: this.quantity,
            required_on_jobsite: this.required_on_jobsite,
            required_on_jobsite_updated: this.required_on_jobsite_updated,
            shakeout_days: this.shakeout_days,
        };
    }

    approveBy(orderDelay: number): Date | null {
        if (this.order_by) {
            return sub(this.order_by, { days: orderDelay });
        }

        return null;
    }
}
