/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `MaterialsSubmittal.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { MaterialsSubmittal } from "./MaterialsSubmittal";

import { Material } from "./Material";
import { Submittal } from "./Submittal";

export abstract class MaterialsSubmittalModel {
    id: number;
    materialId: number;
    submittalId: number;
    material: Material | null | undefined;
    submittal: Submittal | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        materialId: number,
        submittalId: number,
        material: Material | null | undefined,
        submittal: Submittal | null | undefined
    ) {
        this.id = id;
        this.materialId = materialId;
        this.submittalId = submittalId;
        this.material = material;
        this.submittal = submittal;
    }
     

     
    static fromApiObject(json: any, timezone: string): MaterialsSubmittal {
        const newObject = new MaterialsSubmittal(
            json["id"],
            json["material_id"],
            json["submittal_id"],
            json["material"] == null ? json["material"] : Material.fromApiObject(json["material"], timezone),
            json["submittal"] == null ? json["submittal"] : Submittal.fromApiObject(json["submittal"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            material_id: this.materialId,
            submittal_id: this.submittalId,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): MaterialsSubmittal {
        const clone = Object.create(this) as MaterialsSubmittal;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
