/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `Material.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { Material } from "./Material";

import { Audit } from "./Audit";
import { MaterialGroup } from "./MaterialGroup";
import { MaterialsSubmittal } from "./MaterialsSubmittal";
import { Order } from "./Order";
import { Project } from "./Project";

export type leadTimeUnitType = "days" | "weeks" | "months" | "years";
export type orderDelayUnitType = "days" | "weeks" | "months" | "years";
export type reviewDurationUnitType = "days" | "weeks" | "months" | "years";

export const leadTimeUnitTypeValues = {"days":0,"weeks":1,"months":2,"years":3};
export const orderDelayUnitTypeValues = {"days":0,"weeks":1,"months":2,"years":3};
export const reviewDurationUnitTypeValues = {"days":0,"weeks":1,"months":2,"years":3};

export abstract class MaterialModel {
    id: number;
    anticipatedApproval: Dayjs | null;
    anticipatedSubmit: Dayjs | null;
    approvalDate: Dayjs | null;
    approveBy: Dayjs | null;
    archived: boolean | null;
    checkInFrequency: number | null;
    createdAt: Dayjs;
    leadTime: number | null;
    leadTimeUnitType: leadTimeUnitType;
    materialGroupId: number | null;
    materialName: string | null;
    nextOrderBy: Dayjs | null;
    nextRequiredOnJobsite: Dayjs | null;
    orderDelay: number;
    orderDelayUnitType: orderDelayUnitType;
    projectId: number | null;
    requiredOnJobsite: Dayjs | null;
    reviewCycleModifier: number;
    reviewDuration: number;
    reviewDurationUnitType: reviewDurationUnitType;
    specNumber: string | null;
    submitBy: Dayjs | null;
    submitDate: Dayjs | null;
    submittalNumber: string | null;
    unitOfMeasure: string | null;
    updatedAt: Dayjs;
    audits: Audit[] | undefined;
    materialGroup: MaterialGroup | null | undefined;
    materialsSubmittals: MaterialsSubmittal[] | undefined;
    orders: Order[] | undefined;
    project: Project | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        anticipatedApproval: Dayjs | null,
        anticipatedSubmit: Dayjs | null,
        approvalDate: Dayjs | null,
        approveBy: Dayjs | null,
        archived: boolean | null,
        checkInFrequency: number | null,
        createdAt: Dayjs,
        leadTime: number | null,
        leadTimeUnitType: leadTimeUnitType,
        materialGroupId: number | null,
        materialName: string | null,
        nextOrderBy: Dayjs | null,
        nextRequiredOnJobsite: Dayjs | null,
        orderDelay: number,
        orderDelayUnitType: orderDelayUnitType,
        projectId: number | null,
        requiredOnJobsite: Dayjs | null,
        reviewCycleModifier: number,
        reviewDuration: number,
        reviewDurationUnitType: reviewDurationUnitType,
        specNumber: string | null,
        submitBy: Dayjs | null,
        submitDate: Dayjs | null,
        submittalNumber: string | null,
        unitOfMeasure: string | null,
        updatedAt: Dayjs,
        audits: Audit[] | undefined,
        materialGroup: MaterialGroup | null | undefined,
        materialsSubmittals: MaterialsSubmittal[] | undefined,
        orders: Order[] | undefined,
        project: Project | null | undefined
    ) {
        this.id = id;
        this.anticipatedApproval = anticipatedApproval;
        this.anticipatedSubmit = anticipatedSubmit;
        this.approvalDate = approvalDate;
        this.approveBy = approveBy;
        this.archived = archived;
        this.checkInFrequency = checkInFrequency;
        this.createdAt = createdAt;
        this.leadTime = leadTime;
        this.leadTimeUnitType = leadTimeUnitType;
        this.materialGroupId = materialGroupId;
        this.materialName = materialName;
        this.nextOrderBy = nextOrderBy;
        this.nextRequiredOnJobsite = nextRequiredOnJobsite;
        this.orderDelay = orderDelay;
        this.orderDelayUnitType = orderDelayUnitType;
        this.projectId = projectId;
        this.requiredOnJobsite = requiredOnJobsite;
        this.reviewCycleModifier = reviewCycleModifier;
        this.reviewDuration = reviewDuration;
        this.reviewDurationUnitType = reviewDurationUnitType;
        this.specNumber = specNumber;
        this.submitBy = submitBy;
        this.submitDate = submitDate;
        this.submittalNumber = submittalNumber;
        this.unitOfMeasure = unitOfMeasure;
        this.updatedAt = updatedAt;
        this.audits = audits;
        this.materialGroup = materialGroup;
        this.materialsSubmittals = materialsSubmittals;
        this.orders = orders;
        this.project = project;
    }
     

     
    static fromApiObject(json: any, timezone: string): Material {
        const newObject = new Material(
            json["id"],
            json["anticipated_approval"] ? dayjs(json["anticipated_approval"]).tz(timezone) : null,
            json["anticipated_submit"] ? dayjs(json["anticipated_submit"]).tz(timezone) : null,
            json["approval_date"] ? dayjs(json["approval_date"]).tz(timezone) : null,
            json["approve_by"] ? dayjs(json["approve_by"]).tz(timezone) : null,
            json["archived"],
            json["check_in_frequency"],
            dayjs(json["created_at"]).tz(timezone),
            json["lead_time"],
            json["lead_time_unit_type"],
            json["material_group_id"],
            json["material_name"],
            json["next_order_by"] ? dayjs(json["next_order_by"]).tz(timezone) : null,
            json["next_required_on_jobsite"] ? dayjs(json["next_required_on_jobsite"]).tz(timezone) : null,
            json["order_delay"],
            json["order_delay_unit_type"],
            json["project_id"],
            json["required_on_jobsite"] ? dayjs(json["required_on_jobsite"]).tz(timezone) : null,
            json["review_cycle_modifier"],
            json["review_duration"],
            json["review_duration_unit_type"],
            json["spec_number"],
            json["submit_by"] ? dayjs(json["submit_by"]).tz(timezone) : null,
            json["submit_date"] ? dayjs(json["submit_date"]).tz(timezone) : null,
            json["submittal_number"],
            json["unit_of_measure"],
            dayjs(json["updated_at"]).tz(timezone),
            json["audits"]?.map((item: any) => Audit.fromApiObject(item, timezone)),
            json["material_group"] == null ? json["material_group"] : MaterialGroup.fromApiObject(json["material_group"], timezone),
            json["materials_submittals"]?.map((item: any) => MaterialsSubmittal.fromApiObject(item, timezone)),
            json["orders"]?.map((item: any) => Order.fromApiObject(item, timezone)),
            json["project"] == null ? json["project"] : Project.fromApiObject(json["project"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            anticipated_approval: this.anticipatedApproval,
            anticipated_submit: this.anticipatedSubmit,
            approval_date: this.approvalDate,
            approve_by: this.approveBy,
            archived: this.archived,
            check_in_frequency: this.checkInFrequency,
            created_at: this.createdAt,
            lead_time: this.leadTime,
            lead_time_unit_type: this.leadTimeUnitType,
            material_group_id: this.materialGroupId,
            material_name: this.materialName,
            next_order_by: this.nextOrderBy,
            next_required_on_jobsite: this.nextRequiredOnJobsite,
            order_delay: this.orderDelay,
            order_delay_unit_type: this.orderDelayUnitType,
            project_id: this.projectId,
            required_on_jobsite: this.requiredOnJobsite,
            review_cycle_modifier: this.reviewCycleModifier,
            review_duration: this.reviewDuration,
            review_duration_unit_type: this.reviewDurationUnitType,
            spec_number: this.specNumber,
            submit_by: this.submitBy,
            submit_date: this.submitDate,
            submittal_number: this.submittalNumber,
            unit_of_measure: this.unitOfMeasure,
            updated_at: this.updatedAt,
            audits: this.audits?.map(item => item.toApiObject()),
            materials_submittals_attributes: this.materialsSubmittals?.map(item => item.toApiObject()),
            orders: this.orders?.map(item => item.toApiObject()),
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): Material {
        const clone = Object.create(this) as Material;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
