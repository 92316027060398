/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `AreaRadius.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { AreaRadius } from "./AreaRadius";

import { Area } from "./Area";

export abstract class AreaRadiusModel {
    id: number;
    areaId: number | null;
    color: string | null;
    createdAt: Dayjs;
    name: string | null;
    opacity: number | null;
    radius: number | null;
    showBorder: boolean | null;
    units: string | null;
    updatedAt: Dayjs;
    area: Area | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        areaId: number | null,
        color: string | null,
        createdAt: Dayjs,
        name: string | null,
        opacity: number | null,
        radius: number | null,
        showBorder: boolean | null,
        units: string | null,
        updatedAt: Dayjs,
        area: Area | null | undefined
    ) {
        this.id = id;
        this.areaId = areaId;
        this.color = color;
        this.createdAt = createdAt;
        this.name = name;
        this.opacity = opacity;
        this.radius = radius;
        this.showBorder = showBorder;
        this.units = units;
        this.updatedAt = updatedAt;
        this.area = area;
    }
     

     
    static fromApiObject(json: any, timezone: string): AreaRadius {
        const newObject = new AreaRadius(
            json["id"],
            json["area_id"],
            json["color"],
            dayjs(json["created_at"]).tz(timezone),
            json["name"],
            json["opacity"],
            json["radius"],
            json["show_border"],
            json["units"],
            dayjs(json["updated_at"]).tz(timezone),
            json["area"] == null ? json["area"] : Area.fromApiObject(json["area"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            area_id: this.areaId,
            color: this.color,
            created_at: this.createdAt,
            name: this.name,
            opacity: this.opacity,
            radius: this.radius,
            show_border: this.showBorder,
            units: this.units,
            updated_at: this.updatedAt,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): AreaRadius {
        const clone = Object.create(this) as AreaRadius;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
