/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `AreaPoint.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { AreaPoint } from "./AreaPoint";

import { Area } from "./Area";

export abstract class AreaPointModel {
    id: number;
    areaId: number;
    createdAt: Dayjs;
    lat: number;
    lon: number;
    updatedAt: Dayjs;
    area: Area | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        areaId: number,
        createdAt: Dayjs,
        lat: number,
        lon: number,
        updatedAt: Dayjs,
        area: Area | null | undefined
    ) {
        this.id = id;
        this.areaId = areaId;
        this.createdAt = createdAt;
        this.lat = lat;
        this.lon = lon;
        this.updatedAt = updatedAt;
        this.area = area;
    }
     

     
    static fromApiObject(json: any, timezone: string): AreaPoint {
        const newObject = new AreaPoint(
            json["id"],
            json["area_id"],
            dayjs(json["created_at"]).tz(timezone),
            json["lat"],
            json["lon"],
            dayjs(json["updated_at"]).tz(timezone),
            json["area"] == null ? json["area"] : Area.fromApiObject(json["area"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            area_id: this.areaId,
            created_at: this.createdAt,
            lat: this.lat,
            lon: this.lon,
            updated_at: this.updatedAt,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): AreaPoint {
        const clone = Object.create(this) as AreaPoint;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
