/******
  * This file was generated by a Rake task.
  * Do not modify this file manually.
  * Add changes to the `MaterialGroupsSubmittal.ts` file.
  ******/
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

import { MaterialGroupsSubmittal } from "./MaterialGroupsSubmittal";

import { MaterialGroup } from "./MaterialGroup";
import { Submittal } from "./Submittal";

export abstract class MaterialGroupsSubmittalModel {
    id: number;
    materialGroupId: number;
    submittalId: number;
    materialGroup: MaterialGroup | null | undefined;
    submittal: Submittal | null | undefined;
    _destroy: boolean | undefined;

     
    constructor(
        id: number,
        materialGroupId: number,
        submittalId: number,
        materialGroup: MaterialGroup | null | undefined,
        submittal: Submittal | null | undefined
    ) {
        this.id = id;
        this.materialGroupId = materialGroupId;
        this.submittalId = submittalId;
        this.materialGroup = materialGroup;
        this.submittal = submittal;
    }
     

     
    static fromApiObject(json: any, timezone: string): MaterialGroupsSubmittal {
        const newObject = new MaterialGroupsSubmittal(
            json["id"],
            json["material_group_id"],
            json["submittal_id"],
            json["material_group"] == null ? json["material_group"] : MaterialGroup.fromApiObject(json["material_group"], timezone),
            json["submittal"] == null ? json["submittal"] : Submittal.fromApiObject(json["submittal"], timezone)
        );

        return newObject;
    }

    toApiObject(): any {
        return {
            id: this.id,
            material_group_id: this.materialGroupId,
            submittal_id: this.submittalId,
            _destroy: this._destroy
        };
    }

    // NOTE: This could potentially cause infinite loops if the object graph is circular
    //       If parent and child objects have a reference to each other, problems
    inAlternateTimezone(timezone: string): MaterialGroupsSubmittal {
        const clone = Object.create(this) as MaterialGroupsSubmittal;

        for (const key of Object.keys(this)) {
            if (this[key] instanceof dayjs) {
                // @ts-expect-error: Typescript thinks that this is not a dayjs object even though it is checked on the line before
                clone[key] = this[key].tz(timezone);
            }
            else if (Array.isArray(this[key])) {
                clone[key] = (this[key]).map((item) => item.inAlternateTimezone ? item.inAlternateTimezone(timezone) : item);
            }
            else if (this[key] && this[key].inAlternateTimezone) {
                clone[key] = this[key].inAlternateTimezone(timezone);
            }
        }

        return clone;
    }
}
